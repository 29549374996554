import { inject } from '@angular/core'
import { CanActivateFn, Router, UrlTree } from '@angular/router'
import { TranslocoService } from '@ngneat/transloco'
import { Observable, throwError } from 'rxjs'
import { catchError, first, map } from 'rxjs/operators'

import { UserService } from '~core/services'
import { DialogService } from '~core/services/ui/dialog.service'
import { UpgradeDialogComponent } from '~features/subscription/components/upgrade-dialog/upgrade-dialog.component'

const openUpgradeDialog = (): void => {
  const userService = inject(UserService)
  const dialogService = inject(DialogService)
  const translateService = inject(TranslocoService)
  const {
    plan: { label: plan, maxGoals: limit },
  } = userService.getCurrentUser()
  translateService
    .selectTranslateObject('alerts.goalsLimit', { Body: { plan, limit } })
    .pipe(first())
    .subscribe(({ Title, Body }) => {
      dialogService.open(UpgradeDialogComponent, {
        data: { title: Title, content: Body },
        width: '835px',
      })
    })
}
export const maxKpiGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const router = inject(Router)
  const userService = inject(UserService)

  return userService.canCreateKpi().pipe(
    map((canCreate) => {
      if (!canCreate) {
        openUpgradeDialog()
        // location.pathname.replace() is a workaround
        return router.createUrlTree([location.pathname.replace('goals/new', 'goals')])
      }

      // Limit not reached
      return true
    }),
    catchError((error) => {
      if (error.status === 503) {
        return router.navigate(['maintenance'])
      }
      return throwError(() => error)
    }),
  )
}
